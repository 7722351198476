import { render, staticRenderFns } from "./OrderCart.vue?vue&type=template&id=4c488435"
import script from "./OrderCart.vue?vue&type=script&lang=js"
export * from "./OrderCart.vue?vue&type=script&lang=js"
import style0 from "./OrderCart.vue?vue&type=style&index=0&id=4c488435&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports